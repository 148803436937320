<template>
  <video-bg
    :sources="[`${publicPath}404.mp4`]"
  >
    <v-container
      class="d-flex flex-column fill-height align-center justify-center"
      fluid
    >
      <h2
        class="text-h2 font-weight-black white--text mb-8"
      >
        {{ $route.query.message || message }}
      </h2>
      <v-btn
        to="/"
        x-large
      >
        Back to safety
      </v-btn>
    </v-container>
  </video-bg>
</template>

<script>
export default {
  name: 'ErrorView',
  props: {
    message: {
      type: String,
      default: 'Nothing to see here'
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL
  })
}
</script>
